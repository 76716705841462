import { useLoaderData } from '@remix-run/react';
import { Container } from '@storefront/ui-components/shared/container';
import { type LoaderFunctionArgs, unstable_defineLoader as defineLoader } from '@vercel/remix';

import { NavbarContent } from '~/components/navbar/NavbarContent';
import { userPostalCode } from '~/utils/server/postal-code.server';
import { useFreshAuctionData } from '../../libs/ui-components/hooks/useFreshAuctionData';
import { AuctionListWithPagination } from '../components/auctions/AuctionListWithPagination';
import { withAuctions } from '../utils/server/auctions.server';

export const loader = defineLoader(async (loaderFunctionArgs: LoaderFunctionArgs) => {
  const options = {
    postal_code: await userPostalCode(loaderFunctionArgs.request),
  };

  const { auctions, count, limit, offset } = await withAuctions(loaderFunctionArgs, options);

  return {
    auctions,
    count,
    limit,
    offset,
  };
});

export type HomepageIndexRouteLoader = typeof loader;

export default function IndexRoute() {
  const { auctions, count, limit, offset } = useLoaderData<HomepageIndexRouteLoader>();

  useFreshAuctionData(auctions);

  return (
    <>
      <NavbarContent />

      <Container className="pb-16">
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex-1">
            <AuctionListWithPagination
              auctions={auctions}
              paginationConfig={{ count, offset, limit }}
              context="products"
            />
          </div>
        </div>
      </Container>
    </>
  );
}
